import React from 'react'

function Banner() {
  return (
    <div className='Banner'>
        <h1 id="firstname">Luke</h1>
        <h1 id="surname"><strong>De-Ath</strong></h1>
    </div>
  )
}

export default Banner