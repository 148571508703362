import React from 'react'

function Footer() {
  return (
    <div id="Footer">
        <h3>Luke De-Ath <strong>2024</strong></h3>
    </div>
  )
}

export default Footer