import React from "react";

function ServerManagement() {
  return (
    <div className="ServerManagement">
      <h2>Server Management</h2>
      <div>
        <div>
          <h3>Home Assistant</h3>
          <img src="/Images/home-assistant.png" alt="Home Assistant Logo" />
          <p>
            A lot of time has been put into this system to automate most home
            activities. This includes automating the lighting, the storage and
            output of surplus solar power and controlling anything locally over
            WiFi or ZigBee.
          </p>
          <p>
            <strong>Home Assistant</strong> is really an amazing, simple enough
            tool for making the best use of smart devices!
          </p>
        </div>
        <div>
          <h3>Home NAS</h3>
          <img src="/Images/openmediavault.png" alt="OpenMediaVault Logo" />
          <p>
            The Network Attached Storage (<strong>NAS</strong>) uses OpenMediaVault to share
            documents over the local network. I can still access this securely
            over a VPN when I am not at home.
          </p>
        </div>
        <div>
          <h3>Web Hosting</h3>
          <img src="/Images/dokku-logo.svg" alt="Dokku Logo" />
          <p>I use <strong>Dokku</strong> as an open-source alternative for self-hosting a web server. It is a great tool that I have found to be very easy to use and customize.</p>
          <p>This very site should be hosted using Dokku, and if you do not wish to fuss around with your own web-hosting, I can use this to host your site with ease and scalability!</p>
        </div>
      </div>
    </div>
  );
}

export default ServerManagement;
