import React from "react";
import { NavLink } from "react-router-dom";

function NavBar() {
  return (
    <ul id="NavBar">
      <NavLink to="/">
        <li>Home</li>
      </NavLink>
      <NavLink to="/enquiry">
        <li>Make an Enquiry</li>
      </NavLink>
    </ul>
  );
}

export default NavBar;
