import React, { useState } from "react";
import { FaDocker, FaHtml5, FaNodeJs, FaReact } from "react-icons/fa";
import { SiProxmox, SiSqlite } from "react-icons/si";

function Skills() {
  const [hoveredSkill, setHoveredSkill] = useState(null);
  function handleHover(skillName) {
    setHoveredSkill(skillName);
  }

  return (
    <div className="Skills">
      <h2>
        Skills <strong>/</strong> Qualifications
      </h2>
      <div className="container flex flex-wrap justify-center" onMouseLeave={() => setHoveredSkill(null)}>
        <span onMouseOver={() => handleHover("React")}>
          <FaReact />
        </span>
        <span onMouseOver={() => handleHover("HTML5")}>
          <FaHtml5 />
        </span>
        <span onMouseOver={() => handleHover("Node.js")}>
          <FaNodeJs />
        </span>
        <span onMouseOver={() => handleHover("SQLite3")}>
          <SiSqlite />
        </span>
        <span onMouseOver={() => handleHover("Proxmox VE")}>
          <SiProxmox />
        </span>
        <span onMouseOver={() => handleHover("Docker")}>
          <FaDocker />
        </span>
      </div>
      <div className="skillName">{hoveredSkill}</div>
    </div>
  );
}

export default Skills;
