import React from "react";

function Me() {
  return (
    <div className="Me">
      <h2>Who am I?</h2>
      <div className="flex justify-center align-center half-half">
        <span>
          <p>
            If you're wondering, it's pronounced Luke "Dee"-Ath. My family & I
            think it's of belgian origin!
          </p>
          <p>
            I'm training in my personal time to be a full-stack web developer.
            Currently I am working on creating sites with <strong>React</strong>{" "}
            alongside <strong>Node.js</strong> and <strong>SQLite3</strong>.
          </p>
          <p>Age: 22</p>
        </span>
        <img src="/Images/Me.jpg" alt="Portrait of Luke De-Ath." />
      </div>
    </div>
  );
}

export default Me;
