import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import './App.css';

import Layout from './components/Layout';
import Home from './pages/Home/Home';
import Enquiry from './pages/Enquiry/Enquiry';

const router = createBrowserRouter(createRoutesFromElements(
    <Route path="/" element={ <Layout /> } >
      <Route index element={ <Home /> } />
      <Route path="enquiry" element={ <Enquiry /> } />
    </Route>
))

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
