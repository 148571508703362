import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./Enquiry.css";

import NavBar from "../../components/NavBar";

function Enquiry() {
  window.scrollTo(0, 0);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [details, setDetails] = useState("");
  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "luke_portfolio",
        "template_dmosxcm",
        form.current,
        "0a6MGJoaGtkEBLyCR"
      )
      .then(
        (result) => {
          console.log(result.text);
          e.target.reset();
          alert("Email sent successfully!");
        },
        (error) => {
          console.log(error.text);
          alert("Failed to send email.");
        }
      );
  };

  return (
    <div id="Enquiry">
      <NavBar />
      <form ref={form} onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="email@address.com"
          id="enquiry_email"
          name="enquiry_email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoFocus
          required
        />
        <input
          type="text"
          placeholder="Your Name"
          id="enquiry_name"
          name="enquiry_name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <textarea
          placeholder="General Details of your Enquiry"
          id="enquiry_details"
          name="enquiry_details"
          rows="4"
          value={details}
          onChange={(e) => setDetails(e.target.value)}
        />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default Enquiry;
